import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Hardware  from './pages/Hardware'
import Home  from './pages/Home'
import Quotations  from './pages/Quotations'
import CVShaped from './pages/CV_Shaped'
import CVCapi from './pages/CV_Capi'
import CVBego from './pages/CV_Bego'
import CVBio from './pages/CV_Bio'
import CVLive from './pages/CV_Live'
import CVTeam from './pages/CV_Team'
import CVRappi from './pages/CV_Rappi'
import CVInfi from './pages/CV_Infi'
import CVTailor from './pages/CV_Tailor'
import CVArtisan from './pages/CV_Artisan'
import CVTurntable from './pages/CV_Turntable'
import CVReady from './pages/CV_Ready'
import CVCambioml from './pages/CV_Cambioml'
import CVTypewise from './pages/CV_Typewise'
import CVFurtherai from './pages/CV_Furtherai'
import CVAutarc from './pages/CV_Autarc'
import CVRelari from './pages/CV_Relari'
import CVTrellis from './pages/CV_Trellis'

import CVWorkflow86 from './pages/CV_Workflow86'
import CVCapixai from './pages/CV_Capixai'
import CVDodo from './pages/CV_Dodo'
import CVControlhub from './pages/CV_Controlhub'
import CVLiterably from './pages/CV_Literably'
import CVDreamcraft from './pages/CV_Dreamcraft'
import CVCodecrafters from './pages/CV_Codecrafters'
import CVSyntra from './pages/CV_Syntra'
import CVKaya from './pages/CV_Kaya'
import CVRoeai from './pages/CV_Roeai'
import CVStackai from './pages/CV_Stackai'
import CVReveryai from './pages/CV_Reveryai'
import CVTavus from './pages/CV_Tavus'

export const TheRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>} />
            </Routes>
            <Routes>
                <Route path="/hardware" element={<Hardware/>} />
            </Routes>

            <Routes>
                <Route path="/resume/shaped/docx" element={<CVShaped/>} />
                <Route path="/resume/&u$p/capi" element={<CVCapi/>} />
                <Route path="/resume/@w6&/bego" element={<CVBego/>} />
                <Route path="/resume/9@&$/bio" element={<CVBio/>} />
                <Route path="/resume/&8&@/live" element={<CVLive/>} />
                <Route path="/resume/1&&@/team" element={<CVTeam/>} />
                <Route path="/resume/3@$!/rappi" element={<CVRappi/>} />
                <Route path="/u!$!/resume" element={<CVInfi/>} />
                <Route path="/u1$!/resume" element={<CVTailor/>} />
                <Route path="/41$@/resume" element={<CVArtisan/>} />
                <Route path="/!6$@/resume" element={<CVTurntable/>} />
                <Route path="/!2$$/resume" element={<CVReady/>} />
                <Route path="/7!@$/resume" element={<CVCambioml/>} />
                <Route path="/!5$$/resume" element={<CVTypewise/>} />
                <Route path="/!*2*/resume" element={<CVFurtherai/>} />
                <Route path="/2@4*/resume" element={<CVAutarc/>} />
                <Route path="/8@!0/resume" element={<CVRelari/>} />
                <Route path="/@*@0/resume" element={<CVTrellis/>} />
                <Route path="/3*4@/resume" element={<CVWorkflow86/>} />
                <Route path="/1*@7/resume" element={<CVCapixai/>} />
                <Route path="/@00!/resume" element={<CVDodo/>} />
                <Route path="/@5!!/resume" element={<CVControlhub/>} />
                <Route path="/@!8*/resume" element={<CVLiterably/>} />
                <Route path="/5!*0/resume" element={<CVDreamcraft/>} />
                <Route path="/2*5)/resume" element={<CVCodecrafters/>} />
                <Route path="/@*3@/resume" element={<CVSyntra/>} />
                <Route path="/@@*1/resume" element={<CVKaya/>} />
                <Route path="/@!*!/resume" element={<CVRoeai/>} />
                <Route path="/@3*)/resume" element={<CVStackai/>} />
                <Route path="/*1*!/resume" element={<CVReveryai/>} />
                <Route path="/*@)!/resume" element={<CVTavus/>} />
            </Routes>
        </Router> 
    )
}